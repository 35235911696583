<nav>
    <img src="/assets/img/1_hero/PNG/sakura_logo.png" alt="">
    <div>
        <a href="#menu">MENU</a>
        <a href="#menu">LOCATIONS</a>
        <a href="#how_to_order">ABOUT</a>
        <div class="phone_btn">
            <img src="/assets/img/1_hero/PNG/phone.png" alt="">
            <a href="">0176 54564534</a>
        </div>
    </div>
</nav>
