<h1 class="fontRaleway ">HOW TO ORDER</h1>

<section id="how_to_order" class="fontRaleway">
    <div>
        <img src="./../../../assets/img/2_section_how_to_order/PNG/noodle.png" alt="">
        <p>Pick your <br> noodle</p>
    </div>
    <div>
        <img src="./../../../assets/img/2_section_how_to_order/PNG/broth.png" alt="">
        <p>Pick your <br> broth</p>
    </div>
    <div>
        <img src="./../../../assets/img/2_section_how_to_order/PNG/egg.png" alt="">
        <p>Add your <br> toppings</p>
    </div>
</section>
