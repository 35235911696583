<body>
    <h1 class="fontRaleway">OUR MENU</h1>

    <section id="menu">
        <div>
            <img src="./../../../assets/img/3_section_our_menu/Png/R1.png" alt="">
            <table>
                <tr>
                    <th>Tonkotsu Ramen</th>
                </tr>
                <tr>
                    <td>CHICKEN</td>
                    <td>11,95€</td>
                </tr>
                <tr>
                    <td>BEEF</td>
                    <td>12,95€</td>
                </tr>
                <tr>
                    <td>SHRIMP</td>
                    <td>13,95€</td>
                </tr>
            </table>
        </div>
        <div>
            <table>
                <tr>
                    <th>Tonkotsu Ramen</th>
                </tr>
                <tr>
                    <td>CHICKEN</td>
                    <td>11,95€</td>
                </tr>
                <tr>
                    <td>BEEF</td>
                    <td>12,95€</td>
                </tr>
                <tr>
                    <td>SHRIMP</td>
                    <td>13,95€</td>
                </tr>
            </table>
            <img src="./../../../assets/img/3_section_our_menu/Png/R2.png" alt="">
        </div>
        <div>
            <img src="./../../../assets/img/3_section_our_menu/Png/R3.png" alt="">
            <table>
                <tr>
                    <th>Tonkotsu Ramen</th>
                </tr>
                <tr>
                    <td>CHICKEN</td>
                    <td>11,95€</td>
                </tr>
                <tr>
                    <td>BEEF</td>
                    <td>12,95€</td>
                </tr>
                <tr>
                    <td>SHRIMP</td>
                    <td>13,95€</td>
                </tr>
            </table>
        </div>
    </section>
</body>